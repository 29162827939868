import { defineStore } from 'pinia';
import { Workspace, Company } from '@/api/openapi/api';
import { useCompanies } from '@/composables/workspace';

export const useWorkspaceStore = defineStore('workspace', {
  state: () => ({
    workspace: {
      name: ''
    } as Workspace,
    origWorkspace: {
      name: ''
    } as Workspace,
    workspaceCompanies: {
      list: [] as Array<Company>
    }
  }),
  actions: {
    setWorkspace(workspace: Workspace) {
      this.workspace = workspace;
      this.origWorkspace = workspace;
    },
    setOrigWorkspace(workspace: Workspace) {
      this.origWorkspace = JSON.parse(JSON.stringify(workspace));
    },
    setWorkspaceCompanyUsers(workspaceId: string) {
      const { fetchCompanies, companies } = useCompanies(workspaceId);
      this.workspaceCompanies.list = [];
      fetchCompanies().then(() => {
        this.workspaceCompanies.list = companies.value;
      });
    }
  }
});
