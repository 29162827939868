import { Ref } from 'vue';
import useToggle from './toggle';

export default (
  initialState = false
): {
  isEditing: Ref<boolean>;
  enableEditing: () => void;
  disabledEditing: () => void;
} => {
  const [isEditing, enableEditing, disabledEditing] = useToggle(initialState);
  return { isEditing, enableEditing, disabledEditing };
};
