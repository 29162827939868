import { BasicTargetApi, CmSponsorApi, CompanyApi, WorkspaceApi } from '@/api';
import {
  Account,
  AccountAuthority,
  BasicDivision,
  BasicDivisoinList,
  BasicTarget,
  CmSponsorCmProductList,
  CmSponsorList,
  Company,
  CreateCompanyForm,
  WorkspaceBasicMemberList,
  WorkspaceWorkspaceTypeEnum
} from '@/api/openapi';
import { PRIVILEGE } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useEdit from '@/composables/edit';
import {
  fetchAccountAvatar,
  fetchWorkspaceLogoData
} from '@/composables/fetchImage';
import useLoading from '@/composables/loading';
import useModal from '@/composables/modal';
import { useUserInfoStore } from '@/store/userInfo';
import { useWorkspaceStore } from '@/store/workspace';
import { default as Axios, AxiosResponse, default as axios } from 'axios';
import { Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

interface cropImageArgs {
  imageData: HTMLCanvasElement;
  file: {
    file: HTMLCanvasElement;
    name: string;
    type: string;
  };
}

interface UseWorkspaceNameReturnType {
  isEditing: Ref<boolean>;
  enableEditing: () => void;
  disabledEditing: () => void;
  isChangeNameLoading: Ref<boolean>;
  changeName: (name: string) => void;
  validate: (name: string) => void;
  errorMessage: Ref<string>;
}

interface UseWorkspaceImageReturnType {
  workspaceImage: Ref<string | null>;
  cropTargetImage: Ref<string>;
  isWorkspaceImageModalOpen: Ref<boolean>;
  changeWorkspaceImage: () => void;
  isPutImageLoading: Ref<boolean>;
  isPageLoading: Ref<boolean>;
  onCroppedImage: (args: cropImageArgs) => Promise<void>;
  onLoadImage: ({
    file,
    name,
    type
  }: {
    file: string;
    name: string;
    type: string;
  }) => void;
}

interface UseWorkspaceCompanyReturnType {
  createCompany: (
    workspaceId: string,
    createCompanyForm?: CreateCompanyForm
  ) => void;
}

interface UseCustomTargetReturnType {
  targets: Ref<BasicTarget[] | undefined>;
  targetOptions: Ref<BasicDivision['displayDivisionName'][] | undefined>;
  selectedTarget: Ref<BasicDivision['displayDivisionName']>;
  changeTarget: (targetName: BasicDivision['displayDivisionName']) => void;
  getBasicTargets: () => void;
}

export const useWorkspace = (): {
  getWorkspace: (workspaceId: string) => void;
} => {
  const store = useWorkspaceStore();
  const getWorkspace = async (workspaceId: string) => {
    const response = await WorkspaceApi.getWorkspacesWorkspaceId(
      Number(workspaceId)
    );
    if (!response?.data) return;
    store.setWorkspace(response.data);
    store.setOrigWorkspace(response.data);
  };
  return {
    getWorkspace
  };
};

export const useWorkspaceName = (
  workspaceId: string
): UseWorkspaceNameReturnType => {
  const { isEditing, enableEditing, disabledEditing } = useEdit();
  const errorMessage = ref('');
  const patch = async (workspaceName: string) => {
    errorMessage.value = '';
    if (workspaceName.length >= 256) {
      errorMessage.value = VALIDATION_MESSAGE.maxN(255);
      return false;
    }
    try {
      await WorkspaceApi.putWorkspacesWorkspaceIdEdit(Number(workspaceId), {
        workspaceName
      }).catch(e => {
        // NOTE: @inputのvalidateとのタイミングの問題でawait後に判定するためthen-catchを利用
        errorMessage.value = e.message;
        toast({
          title: e.message,
          variant: 'error'
        });
      });
      disabledEditing();
    } catch (e) {
      // 全体的なエラーをキャッチ
      toast({
        title: e.message,
        variant: 'error'
      });
    }
  };
  const validate = (workspaceName: string) => {
    if (workspaceName.length >= 256) {
      errorMessage.value = VALIDATION_MESSAGE.maxN(255);
      return true;
    }
    errorMessage.value = '';
    return false;
  };
  const [isChangeNameLoading, changeName] = useLoading(patch);

  return {
    isChangeNameLoading,
    isEditing,
    changeName,
    enableEditing,
    disabledEditing,
    validate,
    errorMessage
  };
};

export const useWorkspaceImage = (
  workspaceId: string
): UseWorkspaceImageReturnType => {
  const workspaceImage = ref<string>('');
  const cropTargetImage = ref<string>('');
  const { isModalOpen, openModal, closeModal } = useModal();
  const filename = ref<string>('');
  const filetype = ref<string>('');
  const isPageLoading = ref(false);

  const putWorkspaceImage = async ({ imageData, file }: cropImageArgs) => {
    if (file) {
      filename.value = file.name.replace('jpg', 'jpeg');
      filetype.value = file.type;
    }
    closeModal();
    isPageLoading.value = true;
    imageData.toBlob(async blob => {
      try {
        if (blob) {
          const formData = new FormData();
          formData.append('file', blob, filename.value || 'file.png');
          const result = await axios.put(
            `${process.env.VUE_APP_API_BASE_URL}/workspaces/${workspaceId}/logo-image`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          isPageLoading.value = false;
          filename.value = '';
          filetype.value = '';
          if (200 <= result.status && result.status < 300) {
            workspaceImage.value = imageData.toDataURL();
            toast({
              title: '完了',
              message: `画像を設定しました`,
              variant: 'success'
            });
          } else {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        toast({
          title: '失敗',
          message: `画像の設定に失敗しました`,
          variant: 'error'
        });
        isPageLoading.value = false;
      }
    }, filetype.value);
  };

  const [isPutImageLoading, onCroppedImage] = useLoading(putWorkspaceImage);

  const onLoadImage = ({
    file,
    name,
    type
  }: {
    file: string;
    name: string;
    type: string;
  }) => {
    cropTargetImage.value = file;
    filename.value = name;
    filetype.value = type;
    openModal();
  };

  const changeWorkspaceImage = () => {
    if (!workspaceImage.value) return;
    cropTargetImage.value = workspaceImage.value;
    openModal();
  };

  fetchWorkspaceLogoData(Number(workspaceId)).then(imageData => {
    workspaceImage.value = imageData;
  });

  return {
    workspaceImage,
    cropTargetImage,
    isWorkspaceImageModalOpen: isModalOpen,
    changeWorkspaceImage,
    isPutImageLoading,
    isPageLoading,
    onCroppedImage,
    onLoadImage
  };
};

interface UseWorkspaceUsers {
  count: Ref<number>;
  workspaceUsers: Ref<Array<Account & { imageUrl?: string }>>;
  workspaceUsersSearchText: Ref<string>;
  setWorkspaceUsers: (args: SetWorkspaceUsersArgs) => Promise<void>;
}

type SetWorkspaceUsersArgs = {
  workspaceId: number;
  limit?: number;
  offset?: number;
  privilegeList?: string[];
};

export const useWorkspaceUsers = (): UseWorkspaceUsers => {
  const count = ref<number>(0);
  const workspaceUsers = ref<Array<Account & { imageUrl?: string }>>([]);
  const workspaceUsersSearchText = ref('');

  const setWorkspaceUsers = async ({
    workspaceId,
    limit,
    offset,
    privilegeList
  }: SetWorkspaceUsersArgs) => {
    try {
      const currentSearchText = workspaceUsersSearchText.value;
      const keyword = currentSearchText !== '' ? currentSearchText : undefined;
      const result = await WorkspaceApi.getWorkspacesWorkspaceIdAccountsSearch(
        Number(workspaceId),
        limit,
        offset,
        keyword,
        privilegeList?.includes(PRIVILEGE.primary),
        privilegeList?.includes(PRIVILEGE.owner),
        privilegeList?.includes(PRIVILEGE.admin),
        privilegeList?.includes(PRIVILEGE.member),
        privilegeList?.includes(PRIVILEGE.restricted)
      );
      workspaceUsers.value =
        result.data.list?.map(user => ({
          ...user,
          name: user.nickname || `${user.lastName} ${user.firstName}`
        })) ?? [];
      for (const user of workspaceUsers.value) {
        if (user.id?.toString()) {
          fetchAccountAvatar(user.id?.toString()).then(imageData => {
            if (!imageData) return;
            user.imageUrl = imageData;
          });
        }
      }
      count.value = result.data.totalCount ?? 0;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    count,
    workspaceUsers,
    workspaceUsersSearchText,
    setWorkspaceUsers
  };
};

interface useCompaniesReturnTypes {
  fetchCompanies: () => Promise<void>;
  companies: Ref<Array<Company>>;
  currentCompany: Ref<Company | null>;
  currentCompanyName: Ref<string>;
  currentCompanyId: Ref<number>;
  setCompany: (name: string) => void;
}

export const useCompanies = (workspaceId: string): useCompaniesReturnTypes => {
  const companies = ref<Array<Company>>([]);
  const currentCompany = ref<Company | null>(null);
  const currentCompanyName = ref<string>('');
  const currentCompanyId = ref<number>(0);
  const fetchCompanies = async () => {
    try {
      const value = await WorkspaceApi.getWorkspacesWorkspaceIdCompanies(
        Number(workspaceId)
      );
      companies.value = value.data.list ?? [];
      // カンパニーが１つの場合のみ初期設定する
      if (companies.value.length === 1) {
        currentCompany.value = companies.value[0];
        currentCompanyName.value = currentCompany.value.name ?? '';
        currentCompanyId.value = currentCompany.value.id ?? 0;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setCompany = (name: string) => {
    currentCompany.value =
      companies.value.find(company => company.name === name) ?? null;
    currentCompanyName.value = currentCompany.value?.name ?? '';
    currentCompanyId.value = currentCompany.value?.id ?? 0;
  };

  return {
    fetchCompanies,
    companies,
    currentCompany,
    currentCompanyName,
    currentCompanyId,
    setCompany
  };
};

interface useCompanyUsersReturnType {
  count: Ref<number>;
  companyUsers: Ref<Array<Account & { imageUrl?: string }>>;
  companyUsersSearchText: Ref<string>;
  setCompanyUsers: (args: SetCompanyUsersArgs) => Promise<void>;
}

type SetCompanyUsersArgs = {
  companyId: number;
  limit?: number;
  offset?: number;
  privilegeList?: string[];
};

export const useCompanyUsers = (): useCompanyUsersReturnType => {
  const count = ref(0);
  const companyUsers = ref<Array<Account & { imageUrl?: string }>>([]);
  const companyUsersSearchText = ref('');
  const setCompanyUsers = async ({
    companyId,
    offset,
    limit,
    privilegeList
  }: SetCompanyUsersArgs) => {
    try {
      const currentSearchText = companyUsersSearchText.value;
      const keyword = currentSearchText !== '' ? currentSearchText : undefined;
      const fetchCompanyAccounts = await CompanyApi.getCompaniesCompanyIdAccountsSearch(
        Number(companyId),
        offset,
        limit,
        keyword,
        privilegeList?.includes(PRIVILEGE.primary),
        privilegeList?.includes(PRIVILEGE.owner),
        privilegeList?.includes(PRIVILEGE.admin),
        privilegeList?.includes(PRIVILEGE.member),
        privilegeList?.includes(PRIVILEGE.restricted)
      );
      companyUsers.value =
        fetchCompanyAccounts.data.list?.map(account => ({
          ...account,
          name: account.nickname || `${account.lastName} ${account.firstName}`
        })) ?? [];

      for (const user of companyUsers.value) {
        if (user.id) {
          fetchAccountAvatar(user.id?.toString()).then(imageData => {
            if (!imageData) return;
            user.imageUrl = imageData;
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    count,
    companyUsers,
    companyUsersSearchText,
    setCompanyUsers
  };
};

const _addAuthority = async ({
  companyId,
  accounts
}: {
  companyId: string;
  accounts: Array<Account>;
}): Promise<AxiosResponse<AccountAuthority[]>> => {
  return CompanyApi.postCompaniesCompanyIdAccountAuthorities(
    Number(companyId),
    {
      accountIds: accounts.map(value => value.id ?? 0)
    }
  );
};

const [isPostingAuthority, addAuthority] = useLoading(_addAuthority);
export { isPostingAuthority, addAuthority };
export { isDeletingAuthority, deleteAuthority };

const _deleteAuthority = async ({
  companyId,
  accounts
}: {
  companyId: string;
  accounts: Array<Account>;
}): Promise<AxiosResponse<void>> => {
  return CompanyApi.deleteCompaniesCompanyIdAccountAuthorities(
    Number(companyId),
    {
      accountIds: accounts?.map(value => value.id ?? 0)
    }
  );
};
const [isDeletingAuthority, deleteAuthority] = useLoading(_deleteAuthority);

export const useWorkspaceCompany = (): UseWorkspaceCompanyReturnType => {
  const createCompany = async (
    workspaceId: string,
    createCompanyForm?: CreateCompanyForm
  ) => {
    await WorkspaceApi.postWorkspacesWorkspaceIdCompanies(
      Number(workspaceId),
      createCompanyForm
    );
  };
  return {
    createCompany
  };
};

export const useCustomTarget = (): UseCustomTargetReturnType => {
  const basicTargets = ref<BasicDivisoinList>();
  const targets = ref<BasicTarget[] | undefined>();
  const targetOptions = ref<BasicDivision['displayDivisionName'][]>([]);
  const selectedTarget = ref<BasicDivision['displayDivisionName']>('');

  const getBasicTargets = async () => {
    const response = await BasicTargetApi.getBasicTargets();
    basicTargets.value = response.data;

    if (!basicTargets.value.list) return;

    selectedTarget.value = basicTargets.value.list[0].displayDivisionName;
    targetOptions.value = basicTargets.value.list.map(
      target => target.displayDivisionName || ''
    );
    targets.value = basicTargets.value.list.find(
      target => target.displayDivisionName === selectedTarget.value
    )?.targets;
  };

  const changeTarget = (targetName: BasicDivision['displayDivisionName']) => {
    const selectedTarget = basicTargets.value?.list?.find(
      target => target.displayDivisionName === targetName
    );
    targets.value = selectedTarget?.targets;
  };

  return {
    targets,
    targetOptions,
    selectedTarget,
    changeTarget,
    getBasicTargets
  };
};

interface UseCompanyAccessibleAccounts {
  accessibleAccounts: Ref<Array<Account>>;
  accessibleAccountsSearchText: Ref<string>;
  ownerAccounts: Ref<Array<Account>>;
  isSearchingAcc: Ref<boolean>;
  setCompanyAccessibleAccounts: (companyId: number) => Promise<void>;
}

export const useCompanyAccessibleAccounts = (): UseCompanyAccessibleAccounts => {
  const accessibleAccounts = ref<Array<Account>>([]);
  const accessibleAccountsSearchText = ref('');
  const ownerAccounts = ref<Array<Account>>([]);

  const _setCompanyAccessibleAccounts = async (companyId: number) => {
    const {
      companyUsers,
      companyUsersSearchText,
      setCompanyUsers
    } = useCompanyUsers();
    companyUsersSearchText.value = accessibleAccountsSearchText.value;
    await setCompanyUsers({ companyId });
    accessibleAccounts.value = companyUsers.value.filter(
      ca =>
        ca.accountPrivilege !== PRIVILEGE.owner &&
        ca.accountPrivilege !== PRIVILEGE.primary &&
        ca.accountPrivilege !== PRIVILEGE.freeOwner
    );
    ownerAccounts.value = companyUsers.value.filter(
      ca =>
        ca.accountPrivilege === PRIVILEGE.owner ||
        ca.accountPrivilege === PRIVILEGE.primary ||
        ca.accountPrivilege === PRIVILEGE.freeOwner
    );
  };

  const [isSearchingAcc, setCompanyAccessibleAccounts] = useLoading(
    _setCompanyAccessibleAccounts
  );

  return {
    accessibleAccounts,
    accessibleAccountsSearchText,
    ownerAccounts,
    isSearchingAcc,
    setCompanyAccessibleAccounts
  };
};

interface UseCompanyInaccessibleAccounts {
  inaccessibleAccounts: Ref<Array<Account>>;
  inaccessibleAccountsSearchText: Ref<string>;
  isSearchingInAcc: Ref<boolean>;
  setCompanyInaccessibleAccounts: (params: {
    companyId: number;
    workspaceId: number;
  }) => Promise<void>;
}

export const useCompanyInaccessibleAccounts = (): UseCompanyInaccessibleAccounts => {
  const inaccessibleAccounts = ref<Array<Account>>([]);
  const inaccessibleAccountsSearchText = ref('');

  const _setCompanyInaccessibleAccounts = async ({
    companyId,
    workspaceId
  }: {
    companyId: number;
    workspaceId: number;
  }) => {
    const {
      workspaceUsers,
      workspaceUsersSearchText,
      setWorkspaceUsers
    } = useWorkspaceUsers();
    const {
      companyUsers,
      companyUsersSearchText,
      setCompanyUsers
    } = useCompanyUsers();
    workspaceUsersSearchText.value = inaccessibleAccountsSearchText.value;
    companyUsersSearchText.value = inaccessibleAccountsSearchText.value;
    await Promise.all([
      setWorkspaceUsers({ workspaceId }),
      setCompanyUsers({ companyId })
    ]);
    inaccessibleAccounts.value = workspaceUsers.value.filter(wa =>
      companyUsers.value.every(ca => ca.id !== wa.id)
    );
  };

  const [isSearchingInAcc, setCompanyInaccessibleAccounts] = useLoading(
    _setCompanyInaccessibleAccounts
  );

  return {
    inaccessibleAccounts,
    inaccessibleAccountsSearchText,
    isSearchingInAcc,
    setCompanyInaccessibleAccounts
  };
};

interface UseMovedUserReturnType {
  rightUserIds: Ref<Array<number>>;
  leftUserIds: Ref<Array<number>>;
  setRightUsers: (userIds: Array<number>) => void;
  setLeftUsers: (userIds: Array<number>) => void;
}

export const useMovedUser = (): UseMovedUserReturnType => {
  const rightUserIds = ref<Array<number>>([]);
  const leftUserIds = ref<Array<number>>([]);
  const setRightUsers = (userIds: Array<number>) => {
    rightUserIds.value = [...rightUserIds.value, ...userIds];
    leftUserIds.value = leftUserIds.value.filter(id => !userIds.includes(id));
  };
  const setLeftUsers = (userIds: Array<number>) => {
    leftUserIds.value = [...leftUserIds.value, ...userIds];
    rightUserIds.value = rightUserIds.value.filter(id => !userIds.includes(id));
  };

  return {
    rightUserIds,
    leftUserIds,
    setRightUsers,
    setLeftUsers
  };
};

interface useSearchCmCompanyReturnType {
  cmSponsorName: Ref<string>;
  searchResult: Ref<CmSponsorList | undefined>;
  isSearching: Ref<boolean>;
  searchCmCompany: (params: string) => Promise<void>;
  resetSearchResult: () => void;
}

export const useSearchCompany = (
  initialCompany?: CompanyInfo
): useSearchCmCompanyReturnType => {
  const cmSponsorName = ref(initialCompany?.name ?? '');
  const searchResult = ref<CmSponsorList>();

  const _searchCmCompany = async () => {
    try {
      const result = await CmSponsorApi.getCmSponsorsSearch(
        undefined,
        undefined,
        cmSponsorName.value || undefined
      );
      searchResult.value = result?.data;
    } catch (e) {
      console.error(e);
    }
  };
  const [isSearching, searchCmCompany] = useLoading(_searchCmCompany);

  const resetSearchResult = () => (searchResult.value = undefined);

  return {
    cmSponsorName,
    isSearching,
    searchResult,
    searchCmCompany,
    resetSearchResult
  };
};

interface UseSelectCompanyReturnType {
  isLoadingProducts: Ref<boolean>;
  fetchProducts: (params: string) => Promise<void>;
  resetProducts: () => void;
  products: Ref<CmSponsorCmProductList | undefined>;
}

export const useCompanyProducts = (
  initialCompanyId?: string
): UseSelectCompanyReturnType => {
  const products = ref<CmSponsorCmProductList>();

  const _fetchProducts = async (cmSponsorId: string) => {
    try {
      const result = await CmSponsorApi.getCmSponsorsCmSponsorIdCmProducts(
        Number(cmSponsorId)
      );
      products.value = result?.data;
    } catch (e) {
      console.error(e);
    }
  };
  const [isLoadingProducts, fetchProducts] = useLoading(_fetchProducts);

  const resetProducts = () => (products.value = undefined);

  if (initialCompanyId) {
    fetchProducts(initialCompanyId);
  }

  return {
    isLoadingProducts,
    fetchProducts,
    resetProducts,
    products
  };
};

type CompanyInfo = { id?: number; name: string };

interface useCreateCompanyReturnType {
  company: Ref<CompanyInfo>;
  isPostingCompany: Ref<boolean>;
  createCompany: (cmSponsorId: string) => Promise<void>;
  setCompanyInfo: (companyInfo: CompanyInfo) => void;
}

export const useCreateCompany = (): useCreateCompanyReturnType => {
  const company = ref<CompanyInfo>({ name: '' });
  const route = useRoute();
  const workspaceId = route.params.workspaceId as string | undefined;
  const _postCompany = async (cmSponsorId: string) => {
    const form: CreateCompanyForm = {
      name: company.value.name,
      cmSponsorId: parseInt(cmSponsorId)
    };
    try {
      if (workspaceId) {
        await WorkspaceApi.postWorkspacesWorkspaceIdCompanies(
          Number(workspaceId),
          form
        );
        toast({
          title: 'カンパニー作成完了',
          message: `「${company.value.name}」作成完了しました`,
          variant: 'success'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'カンパニー作成失敗');
      } else {
        toast({
          title: 'カンパニー作成失敗',
          message: `「${company.value.name}」作成に失敗しました`,
          variant: 'error'
        });
      }
    }
  };
  const [isPostingCompany, postCompany] = useLoading(_postCompany);

  const createCompany = async (cmSponsorId: string) => {
    await postCompany(cmSponsorId);
    const workspaceStore = useWorkspaceStore();
    if (workspaceId) {
      workspaceStore.setWorkspaceCompanyUsers(workspaceId);
    }
  };

  const setCompanyInfo = (companyInfo: CompanyInfo) => {
    company.value = companyInfo;
  };

  return {
    company,
    isPostingCompany,
    createCompany,
    setCompanyInfo
  };
};

interface UseValidateCompanyReturnType {
  errorMessage: Ref<string>;
  validateDuplicateCompany: (value: string) => void;
  resetErrorMessage: () => void;
}

export const useValidateCompany = (): UseValidateCompanyReturnType => {
  const {
    params: { workspaceId }
  } = useRoute();
  const { companies, fetchCompanies } = useCompanies(workspaceId.toString());
  const errorMessage = ref('');
  const validateDuplicateCompany = async (companyName: string) => {
    await fetchCompanies();
    errorMessage.value = companies.value.some(
      company => company.name === companyName
    )
      ? VALIDATION_MESSAGE.companyDuplicate
      : '';
  };
  const resetErrorMessage = () => (errorMessage.value = '');

  return { errorMessage, validateDuplicateCompany, resetErrorMessage };
};

interface UseWorkspaceMemberList {
  memberList: Ref<WorkspaceBasicMemberList>;
}

export const useWorkspaceMemberList = (
  workspaceId: string
): UseWorkspaceMemberList => {
  const memberList = ref({
    owner: 0,
    manager: 0,
    member: 0,
    restricted: 0,
    freeOwner: 0,
    freeMember: 0
  }) as Ref<WorkspaceBasicMemberList>;

  const getWorkspaceMemberList = async () => {
    const result = await WorkspaceApi.getWorkspacesWorkspaceIdBasicInfo(
      Number(workspaceId)
    );
    if (result?.data.memberList) {
      memberList.value = result.data.memberList;
    }
  };
  getWorkspaceMemberList();

  return {
    memberList
  };
};

interface UseWorkspaceTypeType {
  isBroadcasterWorkspace: () => Promise<boolean | null>;
  getCurrentWorkspaceType: () => Promise<WorkspaceWorkspaceTypeEnum | null>;
}

export const useWorkspaceType = (): UseWorkspaceTypeType => {
  const route = useRoute();
  const userInfoStore = useUserInfoStore();

  const isBroadcasterWorkspace = async () => {
    await userInfoStore.fetchUserInfo();
    if (userInfoStore.joinableCompanies.length === 0) {
      await userInfoStore.fetchJoinableCompanies();
    }
    const workspace = userInfoStore.currentWorkspaceFromRoute(route);

    if (workspace) {
      const response = await WorkspaceApi.getWorkspacesWorkspaceId(
        workspace.workspaceId
      );
      return (
        response.data.workspaceType === WorkspaceWorkspaceTypeEnum.Broadcaster
      );
    } else {
      return null;
    }
  };

  const getCurrentWorkspaceType = async () => {
    await userInfoStore.fetchUserInfo();
    if (userInfoStore.joinableCompanies.length === 0) {
      await userInfoStore.fetchJoinableCompanies();
    }
    const workspace = userInfoStore.currentWorkspaceFromRoute(route);
    if (workspace) {
      const response = await WorkspaceApi.getWorkspacesWorkspaceId(
        workspace.workspaceId
      );
      return response.data.workspaceType;
    } else {
      return null;
    }
  };

  return {
    isBroadcasterWorkspace,
    getCurrentWorkspaceType
  };
};
